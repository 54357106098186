
<i18n>
{
  "en": {
    "info" : "환자들을 위한, 병원 및 각 분야 전문의들의 Second Opinion 서비스e",
    "desc" : "전문의 소견이 필요한 경우 e"
  },
  "ja": {
    "info" : "환자들을 위한, 병원 및 각 분야 전문의들의 Second Opinion 서비스j",
    "desc" : "전문의 소견이 필요한 경우 j"
  },
  "ko":{
    "info" : "환자들을 위한, 병원 및 각 분야 전문의들의 Second Opinion 서비스",
    "desc" : "전문의 소견이 필요한 경우 "
  }
}
</i18n>
<template>
  <!-- <Scroll /> -->
  <section class="intro-wrapped" v-scrollIcon>
    <SecondOpinionIntro :isImage="false" />
  </section>
  <main class="main">
    <section class="main__section--wide">
      <section class="second-opinion-list card-list">
        <div class="list-header">
          <h3 class="-bold">
            신청내역조회
            <span @click="reqShow = !reqShow" class="-royalBlue">
              <i class="pi pi-chevron-down -bold" v-if="reqShow"> 접기 </i>
              <i class="pi pi-chevron-up -bold" v-else>열기</i>
            </span>
          </h3>
          <a class="-royalBlue" @click="() => router.go(0)">새로고침</a>
        </div>
        <RequestCardList
          :type="RequestListType.Request"
          :kind="RequestKindTypes.SecondOpinion"
          :isShow="reqShow"
        />
        <div class="list-header">
          <h3 class="-bold">
            완료내역
            <span @click="resShow = !resShow" class="-royalBlue">
              <i class="pi pi-chevron-down -bold" v-if="resShow">접기</i>
              <i class="pi pi-chevron-up -bold" v-else>열기</i>
            </span>
          </h3>
          <a class="-royalBlue" @click="() => router.go(0)">새로고침</a>
        </div>
        <RequestCardList
          :type="RequestListType.Response"
          :kind="RequestKindTypes.SecondOpinion"
          :isShow="resShow"
        />
      </section>
    </section>
  </main>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import RequestCardList from "@/components/common/Request/RequestCardList.vue";
import { RequestListType, RequestKindTypes } from "@/models/request";
import { RouterName } from "@/models/common";
import { useI18n } from "vue-i18n";
import SecondOpinionIntro from "@/components/secondOpinion/Intro.vue";
import router from "@/router";

export default defineComponent({
  name: "Second Opinion",
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const { t: l } = useI18n();
    const reqShow = ref(true);
    const resShow = ref(true);

    return {
      RequestListType,
      RequestKindTypes,
      RouterName,
      t,
      l,
      router,
      reqShow,
      resShow,
    };
  },
  components: {
    RequestCardList,
    SecondOpinionIntro,
  },
  methods: {
    toggleInfo(event: any) {
      (this.$refs.overlayInfo as any).toggle(event);
    },
  },
});
</script>